
import { createApp } from '/js/petite-vue/dist/petite-vue.es.js'
import { createAtom } from "/js/vendor/xoid.custom.js"
import { reactiveAtom } from "/js/reactiveAtom.js"
import { globalStore, loggedIn, userDataStore, cartStore } from "/js/store/index.js"

import { Swiper, Mousewheel } from '/js/vendor/swiper.custom.js';

import { mqMatches } from "/js/utils.js";
import i18n from '/js/i18n.js'


/* Прокрутка пунктов главного меню */
let navbar = document.querySelector("#hdr-navbar")
let swiper = null;

enquire.register('screen and (min-width: 1200px)', {
	match: function () {
		if (swiper) swiper.slideTo(0, 0);
	}
})
enquire.register('screen and (max-width: 991.98px)', {
	match: function () {
		if (swiper) {
			swiper.slideTo(0, 0);
			swiper.disable();
		}
		navbar.classList.add("offcanvas","offcanvas-end")
		navbar.setAttribute('tabindex', '-1');
	}
})
enquire.register('screen and (min-width: 992px)', {
	match: function () {
		navbar.classList.remove("offcanvas","offcanvas-end")
		navbar.removeAttribute('tabindex', '');

		if (!swiper) {
			swiper = new Swiper(navbar.querySelector('.swiper'), {
				freeMode: {
					sticky: true,
				},
				mousewheel: true,
				spaceBetween: 10,
				slidesPerView: 'auto',
				slidesOffsetBefore: 20,
				slidesOffsetAfter: 20,
				modules: [Mousewheel],
			});
		}
		else {
			swiper.enable();
		}
	}
});


/* Применение стилей к плавающей шапке */
let hdr = document.querySelector(".page-hdr__main")

function floatHdr() {
	if (document.scrollingElement.scrollTop > 50) hdr.classList.add('floating')
	else hdr.classList.remove('floating')
}
floatHdr()
window.addEventListener("scroll", floatHdr, { passive: true });


/* Привязка данных */
const store = createAtom(read => ({
	my_anounces: read(globalStore.focus('my_anounces')),
	favorites: read(globalStore.focus('favorite_goods')),
	cart: read(globalStore.focus('cart')),
	cartList: read(cartStore),
	messages: read(globalStore.focus('messages')),
	account: read(globalStore.focus('account')),
	avatar: read(userDataStore.focus('img')),
	currency: read(globalStore.focus('currency')),
	city: read(globalStore.focus('city')),
	userLoggedIn: read(loggedIn)
}))


const app = createApp({
	toggleUserNav: false,
	linkTitlesHidden: false,
	store: reactiveAtom(store),

	t: i18n.t,

	mounted() {
		let vm = this
		let sidebar = document.getElementById('lk-sidebar')

		enquire.register('screen and (max-width: 991.98px)', {
			match: function () {
				if (sidebar) vm.toggleUserNav = true;
			},
			unmatch: function () {
				if (sidebar) vm.toggleUserNav = false;
			}
		})
		enquire.register('(max-width: 575.98px), (min-width: 720px) and (max-width: 1199.98px)', {
			match: function () {	vm.linkTitlesHidden = true	},
			unmatch: function () {	vm.linkTitlesHidden = false	}
		})
	},
	$delimiters: ['[[', ']]'],
})
i18n.init().then(() => {
	app.mount('#page__header')
})
